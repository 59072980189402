// Next.js
import Head from 'next/head'

// Types (Next.js)
import type { NextPage } from 'next'

// React components
import Layout from '../components/Layout'
import Home from '../components/pages/Home'
import Seo from '../components/Seo'

// Static API data
import { provideStaticApiData } from '../utils/api'

// GraphQL queries
import { GET_HOME_PAGE } from '../utils/graphql-queries'

//Types
import { Api } from '../types/api'

type Props = Api.StaticData & {
  data?: Api.HomePageData
}

const HomePage: NextPage = ({ data, serviceList }: Props) => {
  const { title, description } = data?.home.data?.attributes.hero || {}

  return (
    (data || null) && (
      <>
        <Seo title={title} description={description} />
        <Layout
          serviceList={serviceList}
          darkBackground={true}
          background='#F5F5F5'
        >
          <Home data={data} />
        </Layout>
      </>
    )
  )
}

export const getStaticProps = provideStaticApiData({ query: GET_HOME_PAGE })

export default HomePage
