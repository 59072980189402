import styled from 'styled-components'

export const VideoCardWrapper = styled.div`
  align-items: center;
  margin-left: -36px;
  margin-right: -36px;
  max-width: 585px;

  @media (min-width: 585px) {
    margin: auto;
  }

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    max-width: 1575px;
    margin-right: -90px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  max-width: 585px;
  width: 100%;
  height: 254px;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    max-width: 1500px;
    height: 851px;
  }
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  line-height: 1;
  display: block;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    width: 1500px;
    height: 851px;
  }

  &::-webkit-media-controls-panel {
    position: relative;
    bottom: 20px;
  }
`

export const VideoOverlay = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      rgba(230, 6, 6, 0.5) 0%,
      rgba(115, 3, 3, 0.5) 100%
    ),
    url('/images/video-placeholder.webp') center center / cover no-repeat;
`

export const VideoPlay = styled.div`
  position: relative;
  z-index: 3;
  cursor: pointer;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    width: 180px;
    height: 180px;
  }

  &:hover {
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(17px);
    --webkit-backdrop-filter: blur(17px);
    background-color: rgba(255, 255, 255, 0.26);
  }
`

export const VideoPlayTriangle = styled.div`
  width: 63px;
  height: 63px;
  position: relative;
  left: 7px;
  background: transparent;
  opacity: 0.76;
  backdrop-filter: blur(0px);
  --webkit-backdrop-filter: blur(0px);
  background-color: rgba(255, 255, 255, 0.5);
  clip-path: polygon(100% 50%, 0 0, 0 100%);

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    left: 10px;
    width: 94px;
    height: 94px;
  }
`
export const TextBox = styled.div`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.textBox.background};
  color: ${({ theme }) => theme.colors.textBox.text};
  padding: 30px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 60px 60px 48px 60px;
    column-count: 2;
  }
`

export const TextBoxWrapper = styled.div`
  position: relative;
  z-index: 6;
  margin-top: -30px;
  padding: 0 36px;
  max-width: 585px;

  @media (min-width: 585px) {
    padding-right: 0px;
  }

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    max-width: 1206px;
    padding: 0 90px;
  }
`

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  padding: 0 36px;

  @media (min-width: 585px) {
    padding-right: 0px;
  }

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    width: 100%;
    align-self: flex-start;
    padding: 0 90px;
    max-width: 1206px;
    display: flex;
    justify-content: flex-end;
  }
`

export const TitleWrapper = styled.div`
  padding: 0 36px;

  @media (min-width: 585px) {
    padding: initial;
    min-width: 64px;
  }

  h2 {
    text-align: left;
    padding: 0 36px;

    @media (min-width: 585px) {
      text-align: left;
      padding: initial;
    }

    @media (${({ theme }) => theme.mediaQueries.xl}) {
      white-space: nowrap;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
