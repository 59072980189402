import styled, { css } from 'styled-components'

// Types
import { ContainerProps } from './TextBox.types'

export const TextBoxWrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    flex-direction: row;
    align-items: flex-end;
    gap: 9px;
  }
`

export const Container = styled.div<ContainerProps>`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.textBox.background};
  color: ${({ theme }) => theme.colors.textBox.text};
  padding: 30px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 60px 60px 48px 60px;
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight};
      `}
  }
`

export const TitleWrapper = styled.div`
  font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme }) => theme.colors.textBox.title};
  font-weight: 300;
  line-height: 1.2;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
  }

  h2,
  h3 {
    color: ${({ theme }) => theme.colors.textBox.title};
    font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
    font-weight: 300;

    @media (${({ theme }) => theme.mediaQueries.lg}) {
      font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
    }
  }
`

export const Title = styled.h2`
  line-height: 1.2;
`

export const OuterTitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textBox.title};
  font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
  font-weight: 300;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
  }

  h2,
  h3 {
    color: ${({ theme }) => theme.colors.textBox.title};
    font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
    font-weight: 300;

    @media (${({ theme }) => theme.mediaQueries.md}) {
      font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
    }
  }

  }
`

export const OuterTitle = styled.h2``
