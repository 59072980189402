// Next.js
import Link from '../../Link'

// Ramda
import { filter, find } from 'ramda'

// React components
import Hero from '../../Hero'
import RegionSelect from '../../RegionSelect'
import TextBox from '../../TextBox'
import ArticleCard from '../../ArticleCard'
import ArticleCardsSwiper from '../../ArticleCardsSwiper'
import VideoCard from '../../VideoCard'
import PromoBanner from '../../PromoBanner'

// Styled Components
import Spacer from '../../../styled-components/shared/Spacer'
import {
  Title,
  MainContainer,
  Divider,
  LightText,
} from '../../../styled-components/shared/common'
import { TextBoxes, ButtonWrapper } from './Home.styles'

// Types
import { Api } from '../../../types/api'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../../utils/media'
import Button from '../../Button'
import { renderStrongs, splitTextBy } from '../../../utils/dom'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'
import { articleLink } from '../../../utils/links'

type Props = {
  data?: Api.HomePageData
}

const Home = ({ data }: Props) => {
  const lookUpLocale = useDictionaryLine(dictionary.searchLocation)
  const allLocale = useDictionaryLine(dictionary.articles.all)
  if (!data) {
    return null
  }

  const { title, description, buttons, image } =
    data?.home.data?.attributes.hero || {}

  const newestArticle =
    data?.homepageArticles?.data?.[0] || data?.articles.data[0]

  const promoBanner = data?.home.data?.attributes.promoBanner

  return (
    <>
      <Hero
        title={title}
        url={buttons?.[0]?.url || '/'}
        description={description}
        image={image}
      />
      <MediaContextProvider disableDynamicMediaQueries>
        <MainContainer alignItems='center'>
          <Media lessThan='md'>
            <Spacer size={60} axis='vertical' />
            <Title align='center'>
              {splitTextBy({ line: lookUpLocale, Right: LightText })}
            </Title>
            <Spacer size={24} axis='vertical' />
            <RegionSelect darkBackground={false} />
            <Spacer size={60} axis='vertical' />
            <Divider />
          </Media>
        </MainContainer>
      </MediaContextProvider>
      <MainContainer alignItems='center'>
        <TextBoxes id='vision'>
          {data?.about.data?.attributes.contentBlock.map(block => {
            return (
              <TextBox
                key={block.title}
                outerTitle={<>{renderStrongs(block.title || '')}</>}
                minHeight='380px'
              >
                {block.description}
              </TextBox>
            )
          })}
        </TextBoxes>
        <Spacer size={60} axis='vertical' />
        {promoBanner &&
        promoBanner.title &&
        promoBanner.description &&
        promoBanner.buttons ? (
          <PromoBanner
            title={promoBanner.title}
            description={promoBanner.description}
            imageUrl={promoBanner.image?.data?.attributes.url || ''}
            imageAlt={promoBanner.title}
            buttonText={promoBanner.buttons[0].title || 'číst více'}
            href={promoBanner.buttons[0].url || '/'}
            openInNewWindow={promoBanner.buttons[0].openInNewWindow || false}
          />
        ) : (
          newestArticle && (
            <ArticleCard
              title={newestArticle.attributes.title}
              date={newestArticle.attributes.date}
              text={newestArticle.attributes.perex}
              href={articleLink(newestArticle)}
              imageUrl={
                newestArticle.attributes.image.data?.attributes.url ||
                '/images/article-placeholder.webp'
              }
              imageAlt={newestArticle.attributes.title}
              highlighted={true}
            />
          )
        )}
      </MainContainer>
      <Spacer size={60} axis='vertical' />
      <ArticleCardsSwiper
        data={
          promoBanner
            ? data.articles.data
            : filter(
                article => article.id !== newestArticle?.id,
                data.articles.data,
              )
        }
      />
      <MainContainer>
        <ButtonWrapper>
          <Link href='/novinky' passHref legacyBehavior>
            <Button variant='contained' arrow={true} link>
              {allLocale}
            </Button>
          </Link>
        </ButtonWrapper>
        <Spacer size={60} axis='vertical' />
        <MediaContextProvider disableDynamicMediaQueries>
          <Media lessThan='lg'>
            <Divider />
            <Spacer size={60} axis='vertical' />
          </Media>
        </MediaContextProvider>
        {data.home.data?.attributes.liabilityBlock && (
          <VideoCard block={data.home.data?.attributes.liabilityBlock} />
        )}
        <Spacer size={60} axis='vertical' />
      </MainContainer>
    </>
  )
}
export default Home
