import styled from 'styled-components'

export const TextBoxes = styled.div`
  isolation: isolate;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1280px;
  width: 100%;
  padding-top: 80px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
    gap: 16px;
    justify-content: center;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    position: relative;
    z-index: 15;
    justify-content: space-between;
    padding-top: 120px;
    margin-top: -200px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 1364px;
  margin: 0 auto;
  width: 100%;
`
