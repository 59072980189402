// Styled Components
import {
  TextBoxWrapper,
  Container,
  TitleWrapper,
  Title,
  OuterTitleWrapper,
  OuterTitle,
} from './TextBox.styles'
import Spacer from '../../styled-components/shared/Spacer'

// Helpers
import { isText } from '../../utils/helpers'

// Types
import { TextBoxProps } from './TextBox.types'

const TextBox = ({ children, title, outerTitle, minHeight }: TextBoxProps) => {
  return (
    <TextBoxWrapper>
      {outerTitle &&
        (isText(outerTitle) ? (
          <OuterTitleWrapper>
            <OuterTitle>{outerTitle}</OuterTitle>
          </OuterTitleWrapper>
        ) : (
          <OuterTitleWrapper>{outerTitle}</OuterTitleWrapper>
        ))}
      <Container minHeight={minHeight}>
        {title &&
          (isText(title) ? (
            <TitleWrapper>
              <Title>{title}</Title>
            </TitleWrapper>
          ) : (
            <TitleWrapper>{title}</TitleWrapper>
          ))}
        {title && (
          <Spacer
            size={30}
            when={{
              md: 36,
            }}
            axis='vertical'
          />
        )}
        <div>{children}</div>
      </Container>
    </TextBoxWrapper>
  )
}
export default TextBox
