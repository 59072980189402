// Next.js
import Link from '../Link'

// React
import { useRef, useState } from 'react'

// React components
import Button from '../Button'

// Styled Components
import {
  VideoCardWrapper,
  TitleWrapper,
  VideoWrapper,
  Video,
  VideoOverlay,
  VideoPlay,
  TextBox,
  TextBoxWrapper,
  ButtonWrapper,
  Container,
  VideoPlayTriangle,
} from './VideoCard.styles'
import { Title, ThinText } from '../../styled-components/shared/common'
import Spacer from '../../styled-components/shared/Spacer'
import { Api } from '../../types/api'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'
import { splitTextBy } from '../../utils/dom'

type Props = {
  block: {
    content?: string
    video: { data: Api.FileData }
    title?: string
  }
}

const VideoCard = ({ block }: Props) => {
  const [overlayVisible, setOverlayVisible] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const wantToKnowMoreLocale = useDictionaryLine(dictionary.home.wantToKnowMore)
  const responsibilityLocale = useDictionaryLine(dictionary.home.responsibility)
  return (
    block && (
      <VideoCardWrapper>
        <TitleWrapper>
          <Title>
            <>
              {splitTextBy({
                line: block.title || responsibilityLocale,
                by: ' ',
                Left: ThinText,
              })}
            </>
          </Title>
        </TitleWrapper>

        <Container>
          <Spacer size={24} axis='vertical' when={{ xl: 1 }} />
          <VideoWrapper>
            {overlayVisible && (
              <VideoOverlay>
                <VideoPlay
                  onClick={() => {
                    setOverlayVisible(false)
                    if (videoRef.current) {
                      videoRef.current.play()
                    }
                  }}
                >
                  <VideoPlayTriangle />
                </VideoPlay>
              </VideoOverlay>
            )}

            <Video
              controls
              ref={videoRef}
              muted={false}
              onEnded={() => {
                setOverlayVisible(true)
              }}
            >
              <source
                src={block.video.data?.attributes.url || ''}
                type='video/mp4'
              />
            </Video>
          </VideoWrapper>
          {block.content && (
            <TextBoxWrapper>
              <TextBox
                dangerouslySetInnerHTML={{ __html: block.content || '' }}
              ></TextBox>
            </TextBoxWrapper>
          )}
          {block.content && (
            <>
              <Spacer size={30} axis='vertical' />
              <ButtonWrapper>
                <Link
                  href='/o-spolecnosti#responsibility'
                  passHref
                  legacyBehavior
                >
                  <Button variant='outlined' arrow={true} link>
                    {wantToKnowMoreLocale}
                  </Button>
                </Link>
              </ButtonWrapper>
            </>
          )}
        </Container>
      </VideoCardWrapper>
    )
  )
}
export default VideoCard
